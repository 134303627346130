// export const handleDownload = async (imageUrl, imageName) => {
//   console.log(imageUrl);
//   fetch(imageUrl)
//     .then((response) => response.blob())
//     .then((blob) => {
//       const imageType = blob.type.split("/");
//       console.log(blob.type.split("/"));
//       const url = window.URL.createObjectURL(new Blob([blob]));
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = `${
//         imageName !== " " || imageName !== undefined || imageName !== null
//           ? imageName
//           : imageType[0]
//       }.${imageType[1]}`;
//       a.click();
//     })
//     .catch((error) => console.log("Error:", error));
// };

import axios from "axios";

export const handleDownload = async (fileUrl, docName) => {
  try {
    const response = await axios.get(fileUrl, {
      responseType: "blob",
    });
    const fileName = response?.config?.url;
    const fileExtension = fileName?.split(".").pop().toLowerCase();
    let mimeType;

    switch (fileExtension) {
      case "pdf":
        mimeType = "application/pdf";
        break;
      case "jpg":
      case "jpeg":
        mimeType = "image/jpeg";
        break;
      case "png":
        mimeType = "image/png";
        break;
      case "jfif":
        mimeType = "image/jfif";
        break;
      case "gif":
        mimeType = "image/gif";
        break;
      case "xls":
      case "xlsx":
      case "xml":
        mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "doc":
      case "docx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "txt":
        mimeType = "text/plain";
        break;
      default:
        mimeType = "application/octet-stream";
    }

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: mimeType })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = docName || "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error downloading image:", error);
  }
};

export const handlePrintDocument = (imageUrl) => {
  fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const printWindow = window.open(url);
      printWindow.onload = () => {
        printWindow.print();
      };
    })
    .catch((error) => console.log("Error:", error));
};
 
export const handleBaseDownload = (label, title) => {
  if (!label) {
    console.error("No label provided for download.");
    return;
    }
  
    const base64String = `data:application/pdf;base64,${label.trim()}`;
    if (!/^data:application\/pdf;base64,/.test(base64String)) {
    console.error("Invalid Base64 string for a PDF file.");
    return;
    }
    
    const link = document.createElement('a');
    link.href = base64String;
    link.download = title && title ? `${title}.png` : "document.png";; 
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const handleBaseDownloadCommon = (labels, title) => {
  if (!labels || !Array.isArray(labels) || labels.length === 0) {
    console.error("No valid labels provided for download.");
    return;
  }

   labels?.forEach((label, index) => {
     console.log(label)
     if (typeof label !== "string") {
       console.error(`Invalid label at index ${index}`);
       return;
     }

     const base64String = `data:application/pdf;base64,${labels?.[0]?.trim()}`;
          
     if (!/^data:application\/pdf;base64,/.test(base64String)) {
       console.error(`Invalid Base64 string for a PDF file at index ${index}.`);
       return;
     }

     const link = document.createElement("a");
     link.href = base64String;
     link.download = title ? `${title}_${index + 1}.pdf` : `document_${index + 1}.pdf`;

     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
   });

  
  const base64String = `data:application/pdf;base64,${labels?.[0].trim()}`;
  if (!/^data:application\/pdf;base64,/.test(base64String)) {
  console.error("Invalid Base64 string for a PDF file.");
  return;
  }
  
  const link = document.createElement('a');
  link.href = base64String;
  link.download = title && title ? `${title}.png` : "document.png";; 

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


export const handleBaseDownloadPng = (label, title) => {
  if (!label) {
    console.error("No label provided for download.");
    return;
    }
  
    const base64String = `data:image/png;base64,${label.trim()}`;
    if (!/^data:image\/png;base64,/.test(base64String)) {
    console.error("Invalid Base64 string for a PNG file.");
    return;
    }
    
    const link = document.createElement('a');
    link.href = base64String;
    link.download = title && title ? `${title}.png` : "document.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const downloadImage = (base64Data, filename) => {
  const img = new Image();
  img.src = base64Data;

img.onload = () => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = img.height;
  canvas.height = img.width;

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((-90 * Math.PI) / 180);
  ctx.drawImage(img, -img.width / 2, -img.height / 2);

  const rotatedBase64 = canvas.toDataURL('image/png');

  const link = document.createElement('a');
  link.href = rotatedBase64;
  link.download = filename;
  document.body.appendChild(link)
;
  link.click();
  document.body.removeChild(link)
;
};
};

export const downloadPDF = (base64Data, filename) => {
  if (!base64Data || typeof base64Data !== "string") {
    console.error("Invalid Base64 data");
    return;
  }

  try {
    // Decode Base64
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename ? `${filename}.pdf` : "document.pdf";

    // Trigger download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error processing Base64 data:", error);
  }
}